<template>
  <div class="login">
	  <div class="leftBox">
		  <img  src="../assets/images/logoMz.png" style="width: 331px;height:73px;margin-left: 290px;">
		  <div class="images">
			 <div class="boxcontent">
				  <div class="title"><h3>通知通告</h3></div>
				 <div class="content" style="padding: 30px;">
				 					  <p style="margin-top: 30px;">各位师生：</p>
				 					  <p style="margin-top: 50px;text-indent: 25px;">当前各系统<b style="color: red;">24小时</b>夜间不断网维护。</p>
				 					  <p style="margin-top:30px;text-align: right;padding-right: 50px;">2023年12月22日</p>
				 </div>
			  </div>
			  
		  </div>
		 <div class="listbox">
		 	  <div class="boxitem">
		 		  <img src="../assets/images/lhxq.png" />
		 		  <div class="title">学校介绍</div>
		 	  </div>
		 	  <div class="boxitem">
		 		  <img src="../assets/images/jiaofei.png" />
		 		  <div class="title">学生网上缴费</div>
		 	  </div>
		 	  <div class="boxitem">
		 		  <img src="../assets/images/shiwubanli.png" />
		 		  <div class="title">学生平台学习</div>
		 	   </div>
		 	  <div class="boxitem">
		 		  <img src="../assets/images/kaoji.png" />
		 		  <div class="title">毕业论文</div>
		 	  </div>
		 </div>
	  </div>
    <div v-if="isb == 1" class="center">
    <!--  <div class="left">
        <div class="logoico">
          <img src="@/assets/images/logoMz.png" alt=""/>
        </div>
        <div class="code" @click="iabapp(2)">
          <img src="@/assets/images/app.png" alt=""/>
        </div>
        <div class="tips">移动端扫码下载APP</div>
      </div> -->
      <div class="right">
        <div class="right-center">
          <div class="title">统一身份验证</div>
          <el-form
              style="margin-top: 40px"
              :model="form"
              status-icon
              ref="form"
              class="demo-ruleForm"
          >
            <el-form-item prop="username">
              <el-input
                  class="borderNone"
                  placeholder="请输入学号/手机号/用户号"
                  style="width: 315px"
                  v-model="form.username"
              >
                <div slot="prefix" class="divslot">
                  <img src="../assets/images/logw3.png" alt=""/>
                </div>
              </el-input>
            </el-form-item>
            <el-form-item prop="password">
              <el-input
                  style="width: 315px; margin-top: 10px"
                  class="borderNone"
                  placeholder="请输入密码"
                  type="password"
                  v-model="form.password"
              >
                <div slot="prefix" class="divslot">
                  <img src="../assets/images/logw2.png"/></div
                >
              </el-input>
            </el-form-item>
            <el-form-item prop="age">
              <el-input
                  style="width: 315px; margin-top: 10px"
                  class="borderNone"
                  v-model="form.vodevalu"
                  placeholder="请输入验证码"
              >
                <div slot="prefix" class="divslot">
                  <img src="@/assets/images/logw1.png"/>
                </div>
                <div slot="suffix" @click="verification">
                  <img :src="form.code" alt=""/>
                  <!-- <img src="@/assets/images/yanzm.png" alt="" /> -->
                </div>
              </el-input>
            </el-form-item>
            <el-form-item>
              <div
                  style="background: #3a9ffc"
                  class="btntt"
                  type="primary"
                  size="medium"
                  @click="login('form')"
              >
                登录
              </div>
            </el-form-item>
          </el-form>
		  
		
          <div class="weix" @click="centerDialogVisible = true">
            <div>
              <img src="../assets/images/weixing.png" alt=""/>
            </div>
            <span>微信扫码登录</span>
          </div>
		  
		  <div @click="iabapp(2)">
		    <img src="@/assets/images/app.png" width="200" style="margin-left: 50px;margin-top: 50px;"/>
		  			 <div style="text-align: center;margin-left: 50px;color: #888;">移动端扫码下载APP</div>
		  </div>
        </div>
      </div>
	  
    </div>

    <div v-if="isb == 2" class="center">
      <div class="left">
        <div class="logoico">
          <img src="../assets/images/logoMz.png" alt=""/>
        </div>
        <div class="codes">
          <img src="../assets/images/bg2.png" @click="iabapp(1)" alt=""/>
        </div>
      </div>
      <div class="right">
        <div class="right-center">
          <div class="title">用户登录</div>
          <el-form
              style="margin-top: 40px"
              :model="form"
              status-icon
              ref="form"
              class="demo-ruleForm"
          >
            <el-form-item prop="username">
              <el-input
                  class="borderNone"
                  placeholder="请输入学号/手机号/用户号"
                  style="width: 215px"
                  v-model="form.username"
                  @blur="openCaptcha()"
              >
                <div slot="prefix" class="divslot">
                  <img src="../assets/images/logw3.png" alt=""/>
                </div>
              </el-input>
            </el-form-item>
            <el-form-item prop="password">
              <el-input
                  style="width: 215px; margin-top: 10px"
                  class="borderNone"
                  placeholder="请输入密码"
                  type="password"
                  v-model="form.password"
              >
                <div slot="prefix" class="divslot">
                  <img src="../assets/images/logw2.png" alt=""/></div>
              </el-input>
            </el-form-item>
            <el-form-item prop="age"  v-if="showCaptcha">
              <el-input
                  style="width: 215px; margin-top: 10px"
                  class="borderNone"
                  v-model="form.vodevalu"
                  placeholder="请输入验证码"
              >
                <div slot="prefix" class="divslot">
                  <img src="../assets/images/logw1.png"/>
                </div>
                <div slot="suffix">
                  <img id="refreshLink" @click="refreshLink()"  :src="captchaUrl" alt=""
                       style="width: 100px;height: 30px"/>
                </div>
              </el-input>
            </el-form-item>
            <el-form-item>
              <!-- <el-button
                style="width: 315px; margin-top: 30px"
                type="primary"
                size="medium"
                @click="login('form')"
                >登录</el-button
              > -->

              <div
                  style="background: #fa9230;width: 215px;"
                  class="btntt"
                  type="primary"
                  size="medium"
                  @click="login('form')"
              >
                登录
              </div>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>
    <div>
      <el-dialog
          style="font-size: 24px"
          title="请用微信扫码登录"
          :visible.sync="centerDialogVisible"
          width="440px"
          top="30vh"
          center
      >
        <wxlogin
            v-if="appid && redirect_uri"
            :appid="appid"
            scope="snsapi_login"
            :redirect_uri="redirect_uri"
            :href="href"
            :state="state"
        ></wxlogin>

        <span style="margin-left: 25%">
          <img src="@/assets/images/ewm1.png" alt=""/>
        </span>
      </el-dialog>
    </div>
    <div class="statement" >
      <!-- 版权所有 © copyright 2023 云南爱尔信教育科技股份有限公司 版权所有 -->
      {{ record.all_rights_reserved }} <br/>
	  地址：昆明市一二一大街134号<br />
	  联系电话：0871-65137809<br />
  <!--    ICP备案：{{ record.record_number }} <br/>
      电信业务审批: {{ record.record_license }} -->
    </div>
  </div>
</template>

<script>
import {nameRule, passRule} from "../utilss/vaildate.js";
import wxlogin from "vue-wxlogin";
import {getToken, setToken} from "@/utilss/setToken.js";
import {
  login,
  students,
  getRecord,
  captcha,
  wechatPcSecret,
} from "@/api/api.js";

export default {
  components: {},
  props: {},
  data() {
    return {
      isb: 1,
      record: {},
      showCaptcha:false,
      captchaUrl: "",
      centerDialogVisible: false,
      url: "",
      verificationImg: "",
      form: {
        username: "",
        password: "",
        vodevalu: "",
        code: "",
        type: "pc",
      },

      appid: "",
      redirect_uri: "",
      state: "1",
      href: "", // 自定义样式链接
    };
  },


  methods: {

    openCaptcha(){
      console.log(this.form.username)
      //判断是否为手机号
      if (/^1[3456789]\d{9}$/.test(this.form.username)) {
        this.showCaptcha = true;
      } else {
        this.showCaptcha = false;
      }
      this.refreshLink();

    },

    //刷新链接
    refreshLink() {
      var link = document.getElementById("refreshLink");
      var data = new Date().getTime();
     this.captchaUrl = "https://jxjy.jz.aierxin.cn/api/login/captcha?"+data+"&username=" + this.form.username;


    },

    // 获取微信appid和回调地址redirect_uri，指定内嵌的路由地址weChatLogin
    async getWeChatUrl() {
      // let res = await wechatPcSecret();
      // console.log(res,'扫码登录');
      // if (res && res.code === "0000") {
      //   const data = res.data;
      //   this.appid = data.appId;
      //   this.redirect_uri = data.wxCallbackUrl + "weChatLogin";
      // }
    },
    iabapp(i) {
      //TODO 点击图片切换暂时注释
      // this.isb = i;
    },

    login(form) {
      login(this.form).then((res) => {
        console.log(res.data, "h");
        if (res.data.msg == "登录成功") {
          setToken("username", res.data.data.username);
          setToken("token", res.data.data.token);
          this.$message({message: res.data.msg, type: "success"});
          this.$router.push("/home");
        } else {
          this.$message({message: res.data.msg, type: "error"});
        }
      });
    },
    base64ToImage(base64) {
      const image = new Image();
      image.src = `data:image/png;base64,${base64}`;
      return image.src;
    },

    // 获取图形验证码
    async verification() {
      // let res = await captcha();
      // // console.log(res, "66");
      // const blob = new Blob([res]);
      // this.form.code = window.URL.createObjectURL(blob);
    },
    // 获取备案
    async getuserinfo() {
      let infon = await getRecord();
      console.log(infon.data.data, "foot");
      this.record = infon.data.data;
    },
  },
  created() {
    var  token = getToken("token");
    if (token) {
      this.$router.push("/home");
    }
    this.getuserinfo();
    this.verification();
    this.getWeChatUrl();

  },
  mounted() {
  },
};
</script>
<style lang="scss" scoped>
.btntt {
  cursor: pointer;
  margin-top: 30px;
  width: 313.09px;
  height: 40px;
  border-radius: 6px;
  opacity: 1;
  font-size: 18px;
  color: #ffffff;
  text-align: center;
  line-height: 40px;
}

.divslot {
  width: 100%;
  height: 100%;

  img {
    width: 70%;
  }
}

.el-input__icon ::v-deep {
  height: 50px;
  font-size: 50px;
}

.el-input ::v-deep {
  .el-input__inner {
    border: 0;
    padding-left: 60px;
  }
}

.el-input ::v-deep {
  .el-input__inner {
    border: 0;
    border-radius: 0px;
    border-bottom: 1px solid #d8d8d8;

    &:focus {
      border-bottom: 1px solid rgb(161, 164, 167);
    }
  }
}

* {
  padding: 0;
  margin: 0;
}

.el-form-item {
  margin-bottom: 10px;
}

.login {
  position: relative;
  width: 100%;
  height: 100vh;
  // background-image: url(@/assets/images/bg.jpg);
  background-size: cover;
  background-position: center center;
}

.center {
  display: flex;
  position: absolute;
  // transform: translate(-50%, -50%);
  // left: 50%;
  top: 0;
  right: 100px;
  width: 660px;
  height: 100%;
  // border-radius: 10px;
  opacity: 1;
  background: #ffffff;
  // box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.3);
}

.leftBox{
	display: flex;
	flex-direction: column;
	position: absolute;
	top: 0;
	left: 0;
	width: calc(100% - 658px);
	height: calc(100% - 120px);
	// background: url("../assets/images/login_bg_c.png") center center;
	// background-color: #147DF3;
	// border-right: 1px solid #2d2d2d ;
	.images{
		height: calc(100% - 250px);
		position: absolute;
		right: 20px;
		top: 120px;
		.boxcontent{
			width:1040px;
			margin-left: 80px;
			background-color: #fff;
			margin-right: 50px;
			padding: 30px;
			border-radius: 10px;
			height: 50vh;
			.title{
				border-bottom: 1px solid #ddd;
				text-align: left;
				font-size: 20px;
			}
		}
	}
	
	.listbox{
		padding-left: 100px;
		display: flex;
		flex-direction: row;
		margin-bottom: 20px;
		position: absolute;
		bottom: 60px;
		right: 150px;
		.boxitem{
			width: 240px;
			height: 150px;
			border: 1px solid #ddd;
		    border-radius: 10px;
			margin: 10px;
			position: relative;
			cursor: pointer;
			.title{
				position: absolute;
				bottom: 0;
				width: 100%;
				height: 40px;
				line-height: 40px;
				text-align: center;
				border-radius: 0 0 10px 10px;
				color: #fff;
				background: rgb(0, 0, 0,0.5);
			}
			
			img{
				width: 240px;
				height: 150px;
				border-radius: 10px;
			}
		}
		
		.boxitem:nth-child(1){
			background-color: red;
		}
		.boxitem:nth-child(3){
			background-color:#ff9900;
		}
		.boxitem:nth-child(2){
			background-color:#ddd;
		}
		.boxitem:nth-child(4){
			background-color:seagreen;
		}
	}
}

.statement {
  position: absolute;
  transform: translate(-50%, -50%);
  left: 50%;
  bottom: -2%;
  width: 100%;
  height: 120px;
  opacity: 1;
  font-family: Source Han Sans CN;
  font-size: 16px;
  font-weight: normal;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0px;
  color: #ffffff;
  background-color: #147DF3;
  padding-top: 20px;
}

.left {
  width: 50%;
  height: 100%;
  // border: 1px solid red;
}

.logoico {
  margin-top: 32px;
  margin-left: 47px;
  height: 54.06px;
}

.logoico img {
  width: 90%;
}

.code {
  position: absolute;
  top: 152px;
  left: 83px;
  // border: 1px dotted #b22121;
  width: 171.92px;
  height: 171.83px;
  opacity: 1;

  img {
    height: 100%;
    width: 100%;
  }
}

.codes {
  // border: 1px solid red;
  position: absolute;
  top: 153px;
  left: 20px;
  // width: 535px;
  height: 208px;
  // opacity: 1;
}

.codes img {
  height: 100%;
}

.tips {
  margin-top: 284px;
  color: #9e9e9e;
  margin-left: 89px;
}

.title {
  font-family: SimHei-Regular;
  font-size: 24px;
  text-align: center;
  font-weight: normal;
  line-height: normal;
  letter-spacing: 0em;
  color: #2d2d2d;
}

.right {
  width: 50%;
  height: 100%;
  // border: 1px solid red;
  .el-form {
    width: 200px;
    // border: 1px solid rgb(23, 29, 90);
  }

  .el-input {
    border: none;
  }

  .el-form .el-form-item__label {
    color: #fff;
    display: flex;
  }
}

.el-input {
  border: 2px solid #ff9900;
}

.borderNone.el-input__inner {
  border: none;
}

.right-center {
  margin-left: 34px;
  // border: 1px solid red;
  margin-top: 80px;
  margin-right: 71px;
}

.el-input__inner {
  border: none;
  background-color: rgba(255, 255, 255, 0);
}

.weix {
  cursor: pointer;
  display: flex;
  margin-top: 36px;
  // margin-left: 50%;
  // transform: translate(-50%, -50%);
  width: 200px;
  justify-items: center;
  margin-left: 50px;
  // border: 1px solid blue;
  // background-color: red;
  height: 30px;
  opacity: 1;
  font-family: SimHei-Regular;
  font-size: 16px;
  font-weight: normal;
  line-height: 30px;
  letter-spacing: 0em;
  color: #9e9e9e;
}

.weix div {
  margin-right: 20px;
}
</style>
