
import { Exception } from 'sass'
import service from '../service.js'
import qs from 'qs'

// 备案信息
export function getRecord() {
    return service({
        method: 'post',
        url: '/index/getRecord',
    })
}
// 登录接口
export function login(data) {
    return service({
        method: 'post',
        url: '/login/index',
        data
    })
}

//可预约的线下考试列表
export function getOfflineExamList(data) {
    return service({
        method: 'post',
        url: '/exam/getOfflineExamTip',
        data
    })
}

//预约的线下考试
export function offlineExam(data) {
    return service({
        method: 'post',
        url: '/exam/offlineExam',
        data
    })
}

// 退出登录
export function logout() {
    return service({
        method: 'get',
        url: '/login/logout'
    })
}
// 获取基础信息
export function info() {
    return service({
        method: 'get',
        url: '/user/info',
    })
}
// 获取已学待学课程
export function getMyCourseStatus(data) {
    return service({
        method: 'post',
        url: '/subject/getMyCourseStatus',
        data
    })
}
// 学习中心
export function getStudyCourse() {
    return service({
        method: 'get',
        url: '/Subject/getStudyCourse',
    })
}
// 课程章节和试题
export function getCoursewareList(data) {
    console.log(data);
    return service({
        method: 'post',
        url: '/subject/getCoursewareList',
        data
    })
}
//获取课件播放地址
export function getCoursewareUrl(data) {
    console.log(data);
    return service({
        method: 'post',
        url: 'subject/getCoursewareUrl',
        data
    })
}
//  写入课件进度
export function setStudyRecord(data) {
    return service({
        url: '/Subject/setStudyRecord',
        method: 'post',
        data
    })
}
// 考试部分
// 获取课程指定试卷
export function getPaper(data) {
    return service({
        method: 'post',
        url: '/exam/getPaper',
        data
    })
}
// 获取试卷题目
export function getPaperQuestion(data) {
    return service({
        url: '/exam/getPaperQuestion',
        method: 'post',
        data
    })
}
// 获取考试的科目list
export function getPaperList(data) {
    return service({
        url: '/exam/getPaperList',
        method: 'post',
        data
    })
}

//线下考试详情
export function getOfflineExamDetail(data) {
    return service({
        url: 'exam/getOfflineExamDetail',
        method: 'post',
        data
    })
}

// 获取考试须知
export function getExamNotice() {
    return service({
        url: '/user/getExamNotice',
        method: "post",
    })
}
// 试卷提交题目
export function submitAnswer(data) {
    return service({
        url: '/exam/submitAnswer',
        method: 'post',
        data
    })
}
// 学籍
// 学籍异动
// User/getStudentRecord
export function getStudentRecord() {
    return service({
        url: 'User/getStudentRecord',
        method: 'post',
    })
}
// 实践报告列表
// /Practice/getPracticeInfoList
export function getPracticeInfoList() {
    return service({
        url: '/Practice/getPracticeInfoList',
        method: 'post',
    })
}
// 查询线上作业
export function getOnlineHomework(data) {
    return service({
        url: '/Subject/getOnlineHomework',
        method: 'post',
        data
    })
}

// 成绩列表
export function getMyExamRecords(data) {
    return service({
        url: '/exam/getMyExamRecords',
        method: "post",
        data
    })
}

// 开始考试
export function startExam(data) {
    return service({
        url: '/exam/startExam',
        method: 'post',
        data
    })
}
// 结束考试
export function endExam(data) {
    return service({
        url: 'exam/endExam',
        method: 'post',
        data
    })
}
// 根据id取线上作业
export function getOnlineHomeworkList(data) {
    return service({
        url: '/Subject/getOnlineHomeworkList',
        method: 'post',
        data
    })
}
// 开始线上作业    
export function startOnlineHomework(data) {
    return service({
        url: '/subject/startOnlineHomework',
        method: 'post',
        data
    })
}
// 结束作业
export function submitOnlineHomework(data) {
    return service({
        url: '/subject/submitOnlineHomework',
        method: 'post',
        data
    })
}
// 查看解析
export function getOnlineHomeworkStatus(data) {
    return service({
        url: '/subject/getOnlineHomeworkStatus',
        method: 'post',
        data
    })
}
// 更具id获取线下作业
export function getOfflineHomework(data) {
    return service({
        url: '/Subject/getOfflineHomework',
        method: 'post',
        data
    })
}
// 论文部分
// 论文基础信息
export function getThesisConfig() {
    return service({
        url: '/thesis/getThesisConfig',
        method: 'post',
    })
}
// 论文列表
export function getThesisInfoList() {
    return service({
        url: '/Thesis/getThesisInfoList',
        method: 'post',
    })
}

export function downloadThesis(data) {
    return service({
        url: '/Thesis/downloadThesis',
        method: 'post',
        data
    })
}
// 论文选择
export function uploadThesisInfo(data){
    return service({
        url:'/Thesis/uploadThesisInfo',
        method:'post',
        data
    })
}
//我选择的论文
export function myThesisInfo(){
    return service({
        url:'/Thesis/myThesisInfo',
        method:'post'
    })
}
// 提交开题报告
export function uploadThesisReply(data){
    return service({
        url:'/Thesis/uploadThesisReply',
        method:'post',
        data
    })
}
//开题报告记录
export function getThesisReplyList(){
    return service({
        url:'/Thesis/getThesisReplyList',
        method:'post',
    })
}
// 论文提交
export function uploadThesisFirstDraft(data){
    return service({
        url:'/Thesis/uploadThesisFirstDraft',
        method:'post',
        data
    })
}
// 查询论文记录
export function getThesisFirstDraftList(){
    return service({
        url:'/Thesis/getThesisFirstDraftList',
        method:'post',
    })
}

// 文件上传
export function upload(data){
    return service({
        url:'/Index/upload',
        method:'post',
        data
    })
}
// 提交查重报告
export function uploadThesisCheckReport(data){
    return service({
        url:'/Thesis/uploadThesisCheckReport',
        method:'post',
        data
    })
}
//查重报告记录
export function getThesisCheckReportList(){
    return service({
        url:'/Thesis/getThesisCheckReportList',
        method:'post',
    })
}
// 答辩详情
export function getReply(data){
    return service({
        url:'/Thesis/getReply',
        method:'post',
        data
    })
}
// 学期进度查询
export function index(){
    return service({
        url:'/Graduation/index',
        method:'post',
    })
}
// 用户信息录入详情
export function getInfoDetail(){
    return service({
        url:'/user/getInfoDetail',
        method:'post',
    })
}
// 获取省
export function getProvince(){
    return service({
        url:'/index/getProvince',
        method:'post',
    })
}
// 获取市
export function getCity(data){
    return service({
        url:'/index/getCity',
        method:'post',
        data
    })
}
// 获取县
export function getArea(data){
    return service({
        url:'/index/getArea',
        method:'post',
        data
    })
}
// 获取政治面貌
export function getPolitical(){
    return service({
        url:'/index/getPolitical',
        method:'post',
    })
}
// 获取民族
export function getNation(){
    return service({
        url:'/index/getNation',
        method:'post',
    })
}
// 获取证件类型
export function getCardType(){
    return service({
        url:'/index/getCardType',
        method:'post',
    })
}
// 获取职业
export function getJob(){
    return service({
        url:'/index/getJob',
        method:'post',
    })
}
// 获取文化程度
export function getEducation(){
    return service({
        url:'/index/getEducation',
        method:'post',
    })
}
// 获取身体状况
export function getBodyStatus(){
    return service({
        url:'/index/getBodyStatus',
        method:'post',
    })
}
// 获取亲属关系
export function getFamilyRelation(){
    return service({
        url:'/index/getFamilyRelation',
        method:'post',
    })
}
//录入亲属关系
export function addFamilyMember(data){
    return service({
        url:'/user/addFamilyMember',
        method:'post',
        data
    })
}
// 信息提交
export function saveInfo(data){
    return service({
        url:'/user/saveInfo',
        method:'post',
        data
    })
}
// 获取线下作业答题详情
export function getOfflineHomeworkDetail(data){
    return service({
        url:'/Subject/getOfflineHomeworkDetail',
        method:'post',
        data
    })
}

// 提交线下作业
export function submitOfflineHomework(data){
    return service({
        url:'/Subject/submitOfflineHomework',
        method:'post',
        data
    })
}
// 获取图形验证码
export function captcha(){
    return service({
        url:'/login/captcha',
        method:'post',
        responseType:'blob'
    })
}

// 微信扫码登录
export function wechatPcSecret(){
    return service ({
        url:'/login/wechatPcSecret',
        method:'post',
        
    })
}

// 修改
// export function info(type, data) {
//     data = qs.stringify(data)
//     let obj = { method: type, url: '/info', data }
//     return service(obj)
// }

// 删除
// export function infoDel(id) {
//     return service({
//         method: 'delete',
//         url: `/info/${id}`
//     })
// }


